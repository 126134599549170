import { CardContainer } from "../../../components/Card/CardContainer";
import React, { useEffect } from "react";

import Swal from "sweetalert2";
import PageTitleNested from "../../../components/PageTitle/PageTitleNested";
import { Col, Form, Row } from "antd";
import ProfileImage from "../../../components/ProfileImage/ProfileImage";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import { defaultPropsForm } from "../../../utility/DefaultProps";
import Select from "../../../components/Select/Select";
import styled from "styled-components";
import color from "../../../resource/color";
import Text from "../../../components/Text/Text";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import { SaleListDatasource } from "../../../datasource/SaleListDatasource";
import { useNavigate } from "react-router-dom";
import { zoneDatasource } from "../../../datasource/ZoneDatasource";
import { useEffectOnce } from "react-use";
import { useRecoilValue } from "recoil";
import { profileAtom } from "../../../store/ProfileAtom";
import { roleDatasource } from "../../../datasource/RoleDatasource";
import { useLocalStorage } from "../../../hook/useLocalStorage";
import { checkCompany } from "../../../utility/CompanyName";

const Top = styled.div``;
const Bottom = styled(Row)`
  border-top: 1px solid ${color.background2};
  padding-top: 20px;
  margin-top: 64px;
`;
export function AddNewSale() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [visibleWarning, setVisibleWarning] = React.useState(false);
  const profile = useRecoilValue(profileAtom);
  const [company] = useLocalStorage("profile", null);
  const isInternal = checkCompany(company.company);

  const [zone, setZone] = React.useState<{ label: string; value: string; key: string }[]>([]);
  const [roleList, setRoleList] = React.useState<{ label: string; value: string; key: string }[]>(
    [],
  );
  const { setFieldsValue } = form;
  const getZoneByCompany = async () => {
    const res = await zoneDatasource.getAllZoneByCompany(profile?.company);
    const data = res.map((item: any) => {
      return {
        label: item.zoneName,
        value: item.zoneName,
        key: item.zoneId,
      };
    });
    setZone(data);
  };
  const getRoleList = async () => {
    const { data } = await roleDatasource.getAllRoles({
      page: 1,
      take: 100,
      company: profile?.company,
    });

    const newFormat = data.data.map((el: { rolename: string; roleId: string }) => {
      return {
        label: el.rolename,
        value: el.rolename,
        key: el.roleId,
      };
    });
    setRoleList(newFormat);
  };

  const checkDupTelEmail = async (rule, value) => {
    const field = rule?.field;
    if (field === "telephone" && value.length < 10) {
      return;
    }
    const res = await SaleListDatasource.checkTelEmail(
      field === "telephone" ? null : value,
      field === "telephone" ? value : null,
    );
    if (res && res.success) {
      return;
    } else {
      const errorMessage = field === "telephone" ? "เบอร์โทรศัพท์" : "อีเมล";
      throw new Error(`${errorMessage}ถูกใช้แล้วในระบบ`);
    }
  };

  useEffectOnce(() => {
    getZoneByCompany();
    getRoleList();
  });
  const onFinish = async (values: any) => {
    const zoneString =
      Array.isArray(values.zone) && values.zone.length > 1
        ? values.zone.join(",")
        : typeof values.zone === "string"
          ? values.zone
          : null;
    const updatedValues = {
      ...values,
      zone: zoneString,
      company: profile?.company,
      status: "ACTIVE",
    };

    Swal.fire({
      title: "บันทึกข้อมูลสำเร็จ",
      text: `ลิ้งยืนยันตัวตนผู้ใช้ถูกส่งไปอีเมลผู้ใช้งาน ${values?.email} เรียบร้อยแล้ว กรุณาแจ้งผู้ใช้งาน เพื่อดำเนินการยืนยันตัวตนผู้ใช้งานในอีเมล และกำหนดรหัสผ่านสำหรับเข้าสู่ระบบ`,
      width: 360,
      icon: "success",
      customClass: {
        title: "custom-title",
      },
      timer: 3000,
      showConfirmButton: false,
    }).then(async () => {
      try {
        setLoading(true);
        const res = await SaleListDatasource.createNewSaleStaff(updatedValues);
        if (res && res.success) {
          setLoading(false);
          navigate("/UserPage/SaleManagementPage?status=wait_approve");
        } else {
          Swal.fire({
            title: res.userMessage,
            text: "",
            width: 250,
            icon: "error",
            customClass: {
              title: "custom-title",
            },
            showConfirmButton: false,
          }).then(() => {
            setLoading(false);
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <CardContainer>
      <Form
        {...defaultPropsForm}
        form={form}
        onFinish={onFinish}
        style={{
          flexDirection: "column",
          display: "flex",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Top>
          <PageTitleNested
            title='เพิ่มรายชื่อผู้ใช้งาน'
            onBack={() => {
              const isHaveValue = Object.values(form.getFieldsValue()).some((item) => item);
              if (isHaveValue) {
                setVisibleWarning(true);
              } else {
                navigate(-1);
              }
            }}
          />
          <Row>
            <Form.Item name='profileImage'>
              <ProfileImage />
            </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='firstname'
                label='ชื่อ*'
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกชื่อ",
                  },
                ]}
              >
                <Input placeholder='ระบุชื่อ' autoComplete='off' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='lastname'
                label='นามสกุล*'
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกนามสกุล",
                  },
                ]}
              >
                <Input placeholder='ระบุนามสกุล' autoComplete='off' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name='nickname' label='ชื่อเล่น'>
                <Input placeholder='ระบุชื่อเล่น' autoComplete='off' />
              </Form.Item>
            </Col>
            {isInternal && (
              <Col span={12}>
                <Form.Item name='userNav' label='ชื่อผู้ใช้ Navision'>
                  <Input placeholder='ระบุชื่อผู้ใช้' autoComplete='off' />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='telephone'
                label='เบอร์โทรศัพท์*'
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกเบอร์โทรศัพท์",
                  },
                  {
                    pattern: /^[0-9]*$/,
                    message: "กรุณากรอกเบอร์โทรศัพท์เป็นตัวเลขเท่านั้น",
                  },
                  {
                    min: 10,
                    message: "กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก",
                  },
                  {
                    validator: checkDupTelEmail,
                  },
                ]}
              >
                <Input placeholder='ระบุเบอร์โทรศัพท์' maxLength={10} autoComplete='off' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='email'
                label='อีเมล*'
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกอีเมล",
                  },
                  {
                    type: "email",
                    message: "กรุณากรอกอีเมลให้ถูกต้อง",
                  },
                  {
                    validator: checkDupTelEmail,
                  },
                ]}
              >
                <Input
                  autoComplete='off'
                  placeholder={`${
                    profile?.company?.includes("MGT")
                      ? "ระบุอีเมล"
                      : "ตัวอย่าง iconkaset.i@iconkaset.com"
                  }`}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='role'
                label='บทบาท*'
                rules={[
                  {
                    required: true,
                    message: "กรุณาเลือกบทบาท",
                  },
                ]}
              >
                <Select
                  data={roleList}
                  placeholder='เลือกบทบาท'
                  onChange={() => setFieldsValue({ zone: undefined })}
                />
              </Form.Item>
            </Col>
            <Form.Item
              noStyle
              shouldUpdate={(prev, current) => {
                return prev.role !== current.role;
              }}
            >
              {({ getFieldValue }) => {
                const role = getFieldValue("role");
                const isSaleManager = role === "SALE MANAGER";
                const isFactory = role?.toUpperCase() === "FACTORY";
                const isAdmin = role?.toUpperCase() === "ADMIN";
                const isCoSale = role?.toUpperCase() === "CO SALE";
                const isSaleCo = role?.toUpperCase() === "SALECO";
                return (
                  <>
                    {(role === "SALE" || isSaleManager) && (
                      <Col span={12}>
                        <Form.Item label='เขต' name={"zone"}>
                          <Select data={zone} mode={isSaleManager ? "multiple" : undefined} />
                        </Form.Item>
                      </Col>
                    )}
                    {(isAdmin || isFactory || isCoSale || isSaleCo) && (
                      <Col span={12}>
                        <Form.Item label='ประเภทเอกสาร Navision' name={"docType"}>
                          <Select
                            data={[
                              {
                                label: "SO (Sales Order CO-Sale)",
                                value: "Co-Sales",
                                key: "Co-Sales",
                              },
                              {
                                label: "SP (Sales Order Inventory)",
                                value: "Inventory",
                                key: "Inventory",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </>
                );
              }}
            </Form.Item>
          </Row>
        </Top>
        <Bottom>
          <Col span={22}>
            <Text color='Text3' level={6} fontFamily='Sarabun'>
              โปรดตรวจสอบข้อมูลพนักงานก่อนบันทึก
            </Text>
          </Col>
          <Col span={2}>
            <Button
              title='บันทึก'
              onClick={() => {
                setVisible(true);
              }}
            />
          </Col>
        </Bottom>
      </Form>
      <ConfirmModal
        visible={visible}
        loading={loading}
        onConfirm={() => {
          form.submit();
          setVisible(false);
        }}
        onCancel={() => {
          setVisible(false);
        }}
        title='ยืนยันการบันทึกข้อมูล'
        desc='โปรดยืนยันการบันทึกข้อมูลเพิ่มตำแหน่งชื่อ'
      />
      <ConfirmModal
        visible={visibleWarning}
        onConfirm={() => {
          setVisibleWarning(false);
          navigate(-1);
        }}
        onCancel={() => {
          setVisibleWarning(false);
        }}
        title='คุณต้องการกลับสู่หน้าหลักใช่หรือไม่'
        desc='โปรดยืนยันการกลับสู่หน้าหลัก'
      />
    </CardContainer>
  );
}
